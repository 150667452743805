body {
  margin: 0;
  padding: 0;
  background: #00529b; }
  body .container {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    body .container h1 {
      color: white; }

#loading-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #00529b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; }
